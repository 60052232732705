$bg-black: rgb(29 31 35);
$bg-white: #fff;
$text-purple: rgb(141 99 244);
$text-orange: rgb(237, 81, 63);
@mixin vertical-center {
  align-items: center;
  justify-content: center;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  * {
    box-sizing: border-box;
  }
  background-color: $bg-black;
  box-sizing: border-box;
  color: $bg-white;
  padding-top: 60px;
  .app-header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    z-index: 5;
    padding: 0;
    background-color: $bg-black;
    padding: 0 4rem;
    .lumina-header-app-logo,
    .lumina-header-profile-wrapper {
      display: none;
    }
    .lumina-header-nav-cntnr {
      width: 100%;
      .header-nav-wrapper {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        .header-title {
          font-size: 22px;
          font-weight: bold;
          width: fit-content;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          border-bottom: 2px solid $text-orange;
          em {
            margin-left: 5px;
            margin-top: 2px;
            font-size: 22px;
            font-style: normal;
            color: $text-orange;
          }
        }
      }
    }
  }
  .section-wrapper {
    section {
      width: 100%;
      background-color: rgb(37 39 41);
      min-height: calc(100vh - 60px);

      padding: 3rem 4rem;
      &:nth-child(odd) {
        background-color: $bg-black;
      }

      &.info-section {
        &.intro-section {
          display: flex;
          flex-direction: row;
          @include vertical-center();
          .intro-big-img {
            width: 500px;
            height: 500px;
            border-radius: 50%;
            background-color: #373b41;
            display: flex;
            flex-direction: row;
            @include vertical-center();
            background-image: url("./assets/images/portrait.png");
            background-repeat: no-repeat;
            background-position-x: center;
            background-position-y: center;
            background-size: contain;
            &.photo-2 {
              background-image: url("./assets/images/photo-2.jpg");
              background-size: 150%;
            }
            img {
              height: 90%;
              width: auto;
              display: none;
            }
          }
          .intro-text-section {
            max-width: 70%;
            margin: 0;
            .lumina-text {
              margin-bottom: 3rem;
              transform: scale(1.5);
              margin-left: 20%;
              .colored {
                color: $text-orange;
                font-style: normal;
              }
              &.bold {
                font-weight: 700;
              }
              &.medium-text {
                font-size: 12px;
                line-height: 22px;
              }
            }
            .chip-cntnr {
              display: flex;
              flex-wrap: wrap;
              flex-direction: row;
              align-items: center;
              justify-content: flex-start;
              margin-top: -2rem;
              .chip {
                background-color: $text-purple;
                margin: 4px;
                border-radius: 8px;
                padding: 4px 1rem;
                width: fit-content;
                max-height: 60px;
              }
              margin-bottom: 3rem;
            }
            .contact-row {
              margin-bottom: 10px;
              &.lumina-text {
                a {
                  display: flex;
                  flex-direction: row;
                  width: fit-content;
                  svg {
                    path {
                      stroke: $text-orange;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  body {
    .app-header {
      padding: 0;
      height: 60px;
      .lumina-header-nav-cntnr {
        width: 100%;
        display: flex;
        .header-nav-wrapper {
          width: 100%;
          .lumina-text {
            text-align: center;
            margin: 0 auto;
          }
        }
      }
    }
    .section-wrapper {
      section {
        padding: 1rem 2rem;
        &.info-section {
          &.intro-section {
            flex-direction: column;
            .intro-text-section {
              width: 100%;
              max-width: none;
              .lumina-text {
                transform: none;
                margin-left: 0;
                text-align: center;
              }
            }
            .intro-big-img {
              width: 300px;
              height: 300px;
              margin: 0 auto;
            }
          }
          .vertical-timeline {
            .timeline-icon {
              svg {
                width: 25px !important;
                height: 25px !important;
              }
            }
          }
        }
      }
    }
  }
}
